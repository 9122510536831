.searchFeild {
  /* margin: 25px 0px; */
  flex: 1;
  position: relative;
}

.searchFeild input {
  height: 50px;
  flex: 1;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
}

.searchFeild svg {
  font-size: 20px;
  position: absolute;
  top: 15px;
  left: 15px;
  color: #959595;
}

/*===12-8-24===*/
.searchFeild {
  max-width: 380px;
  width: 100%;
  margin: 20px 0px;
}