.NotificationUnread {
  background-color: rgba(230, 230, 230, 0.47);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
  cursor: pointer;
  display: flex;
}

.NotificationRead {
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
  cursor: pointer;
  display: flex;
}

.NotificationRead h5 {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 6px;
}

.NotificationRead h6 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.NotificationRead p {
  font-size: 10px;
  font-weight: 400;
  color: #828282;
}

.NotificationUnread h5 {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 6px;
}

.NotificationUnread h6 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.NotificationUnread p {
  font-size: 10px;
  font-weight: 400;
  color: #828282;
}

.notificationImg {
  width: 32px;
  height: 32px;
  color: white;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

.topIcon {
  display: flex;
  align-items: center;
}

.notificationSection {
  position: relative;
}

.notificationCount {
  position: absolute;
  right: 4px;
  top: 0px;
  background: #ec3b1e;
  border-radius: 50%;
  padding: 5px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}

.noResultsMessage {
  text-align: center;
}

.deleteIcon {
  color: red;
  height: 20px;
  width: 20px;
}
.actionBtns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.responseBtn {
  font-size: 12px !important;
  padding: 2px 6px !important;
}

.respondedTxt {
  font-size: 12px;
  color: grey;
}
.clrBtn {
  position: fixed;
  width: 100px;
  justify-content: end;
  display: flex;
  background: #fff;
  padding: 10px 13px;
  z-index: 2;
  width: 330px;
  border-bottom: 1px solid #e7e5e5;
  border-radius: 41px 0px 0px 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.notificationSec {
  position: absolute;
  background-color: white;
  left: -293px;
  top: 0;
  width: 330px;
  height: 270px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media only screen and (max-width: 380px) {
  .notificationSec {
    left: -255px;
  }
}
