.CalenderIconProperty {
  width: 30px;
  height: 30px;
  position: relative;
  top: 72px;
  left: 425px;
}

/* .dateCalenderImage{
    position: absolute;
    top: 8px;
    right: 16px;
    width: 32px;
} */
.audioImageContainer {
  /* position: absolute;
    bottom: 40px;
    left: 25px; */
}

.audioImage {
  width: 15px;
}

.audioSpeakerContainer {
  /* position: absolute;
    right: 145px;
    bottom: 40px; */
}

.audioButtonContainer {
  /* position: absolute;
    right: 20px;
    bottom: 31px; */
}

.selectAudioButton {
  background-color: #1d4488;
  color: #ffffff;
  padding: 7px 25px;
  height: 40px;
}

.parentIconButton {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  position: absolute;
  margin-top: 35px;
  align-items: center;
  width: 100%;
  top: 0px;
}

.browseSpeakerButton {
  display: flex;
  align-items: center;
}

.audioSpeakerContainer {
  margin-right: 10px;
}

.checkboxSelect {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.vectorIconField {
  position: relative;
  pointer-events: none;
}

.vectorIcon {
  /* position: absolute;
   bottom: 24px;
   right: 12px; */
  position: absolute;
  top: 44px;
  right: 12px;
}

.parentIconButton1 {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  position: absolute;
  margin-top: 8px;
  align-items: center;
  width: 100%;
  top: 0px;
}
