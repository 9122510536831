.fullBackground {
    justify-content: center;
    background-image:url('../../../Assests/Images/Rectangle181.png');
    width: 100%;
    height: 100vh;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    padding: 50px 10px;
    overflow-y: auto;
  }
  
 .alignmentProperty{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
   
}
.logoSection{
    background-color: #FFFFFF;
    width: 560px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
  }
.formFieldSection{
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.inputFieldSection{
    width:560px;
    min-height: 380px;
    max-height: auto;
    background-color: #FFFFFF;
    border-radius: 9px;
    padding: 30px;
}


.mAuto {
    margin:auto 0px;
}