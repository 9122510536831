.alertText {
  font-size: 22px;
  font-weight: 700;
  color: #000;
}

.deleteClass {
  text-align: center;
}

.titleText {
  font-size: 18px;
  width: 100%;
  font-weight: 700;
  padding: 0px 49px;
  margin: 20px 0px;
  text-align: center;
}
