.backArrowSec {
  max-width: 260px;
  width: 100%;
}
.backArrow {
  padding: 25px 0px;
  display: flex;
  align-items: center;
}

.backArrow span {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-left: 8px;
}

.backArrow svg {
  color: #000;
  font-size: 26px;
}

.backArrowSec {
  max-width: 260px;
  width: 100%;
}
.infoSection h3 {
  color: #000;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}
.tbl {
  width: 100%;
  padding: 0px 20px;
}
.tbl {
  width: 100%;
}

.personImg {
  width: 120px;
  height: 120px;
  border: 3px solid #e3e3e3;
  border-radius: 50%;
}

.personImg img {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
}

.personInfo {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  background-color: #fafafa;
  min-height: 345px;
}
.personImgdiv {
  justify-content: center;
  display: flex;
  padding-bottom: 15px;
}
.personLang h4 {
  font-size: 15px;
  color: #959595;
  font-weight: 500;
  margin-bottom: 10px;
}
.personLang p {
  font-size: 15px;
  color: #202020;
  font-weight: 600;
  margin-bottom: 15px;
}

.personPrompt h4 {
  font-size: 15px;
  color: #959595;
  font-weight: 500;
  margin-bottom: 10px;
}
.personPrompt p {
  font-size: 15px;
  color: #202020;
  font-weight: 600;
  margin-bottom: 10px;
  word-break: break-word;
}
.personName p {
  margin-bottom: 15px;
  font-weight: 600;
  color: #202020;
}
.personName p,
.personLang p,
.personPrompt p::first-letter {
  text-transform: capitalize;
}
.personInfo.basicInfo ul li {
  display: flex;
  margin-bottom: 20px;
  align-items: baseline;
}

.personInfo.basicInfo ul li p {
  color: #828282;
  font-size: 15px;
  font-weight: 500;
  margin-right: 6px;
}

.personInfo.basicInfo ul li span {
  color: #202020;
  font-size: 15px;
  font-weight: 600;
  margin-left: 6px;
  text-align: left;
}

.caregiverList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.caregiverList h3 {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.caregiverList p {
  color: #1d4488;
  font-size: 17px;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}
.infoPerson,
.emailPerson,
.relationInfo {
  display: flex;
  font-size: 15px;
  margin-bottom: 10px;
}
.infoPerson p,
.emailPerson p,
.relationInfo p {
  margin-right: 10px;
  color: #626262;
  font-size: 15px;
  font-weight: 500;
}

.infoPerson span,
.emailPerson span,
.relationInfo span {
  color: #202020;
  font-size: 15px;
  font-weight: 500;
}
.modelButtonPropwerty {
  text-align: right;
  padding: 0px 0px;
}
.alignTab {
  align-items: center;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 6px;
  background-color: #f8f8f8;
  margin: 0px;
  margin-bottom: 10px;
}
ul {
  margin-bottom: 0px;
}
.relationInfo {
  margin-bottom: 0px !important;
}
.timeSlot {
  display: flex;
  justify-content: space-between;
}

p.caregiverName {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.timeSlot p {
  color: #828282;
  padding: 5px 0px;
  font-size: 18px;
  font-weight: 500;
}
.dutyHours {
  color: #202020;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}
p.nameCargiver {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
.timeSlot p:last-of-type {
  color: #333;
  font-size: 500;
  font-size: 18px;
  font-weight: 500;
}
.closedText {
  color: rgb(255, 0, 0) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.audioIntro h4 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
