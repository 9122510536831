.fullBackground {
  justify-content: center;
  background-image: url("../../Assests/Images/Rectangle181.png");
  width: 100%;
  height: 100vh;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  padding: 50px 10px;
  overflow-y: auto;
}

.alignmentProperty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoSection {
  background-color: #ffffff;
  width: 580px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  border-radius: 9px;
  margin-bottom: 20px;
}
.mAuto {
  margin: auto 0px;
}
.formFieldSection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputFieldSection {
  width: 580px;
  min-height: 500px;
  max-height: auto;
  background-color: #ffffff;
  border-radius: 9px;
  padding: 30px;
}
.headingContent {
  color: #000000;
  font-family: Inter;
  font-weight: 600;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0px;
  font-size: 31px;
}
.paragraphText {
  color: #828282;
  font-family: Inter;
  display: flex;
  justify-content: center;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
}
.registerFormsField {
  padding-top: 20px;
}

.buttonAndContent {
  font-family: Inter;
  display: flex;
  justify-content: space-between;
}

.customLinkSignUp {
  color: #1d4488;
  text-decoration: none;
  font-weight: 600;
}

::placeholder {
  color: #555555;
}
@media screen and (max-width: 425px) and (min-width: 320px) {
  .paragraphText {
    color: #828282;
    font-family: Inter;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-size: small;
  }
}
.mainUser {
  background: #fff;
  padding: 10px;
  justify-content: center;
  display: flex;
  width: 100%;
  max-width: 650px;
  border-radius: 10px;
  margin-bottom: 15px;
  height: 90px;
  align-items: center;
}
.userType {
  justify-content: center;
  background-image: url("../../Assests/Images/usertype.png");
  width: 100%;
  height: 100vh;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  padding: 50px 10px;
  overflow-y: auto;
}

.selectFeild {
  margin: auto 0px;
  width: 100%;
  max-width: 650px;
  padding-left: 15px;
  padding-right: 15px;
}

.userText {
  background: #fff;
  padding: 20px;
  justify-content: center;
  width: 100%;
  max-width: 650px;
  border-radius: 10px;
  text-align: center;
  height: auto;
}

.userText h3 {
  color: #000;
  font-size: 31px;
  font-weight: 600;
  margin: 15px 0px;
}

.userText p {
  color: #828282;
  font-size: 17px;
  font-weight: 300;
  max-width: 460px;
}

.userBtn {
  margin-bottom: 20px;
}

.txtSlect {
  width: 100%;
  justify-content: center;
  display: flex;
}

.userBtn {
  margin-top: 40px;
}

.bottomSpace {
  /* margin-bottom: 10px !important; */
  width: 31px;
}

/*======UserType Continue button =======*/

.buttonProperty {
  background: #1d4488 !important;
  border: none !important;
  font-weight: 600 !important;
  min-width: 90px;
  max-width: auto;
  padding: 12px 70px !important;
  font-size: 18px;
  font-size: 18px !important;
}

/* css dial code validation error message  starts here*/
/* .phoneInputFieldProperty {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

/* .phoneInputFieldProperty:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
} */

.errorBorder {
  border-color: red !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;
  border-radius: 5px;
}

.errorMessage {
  color: red;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  border-radius: 5px;
}
