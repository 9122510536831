.userOtpContainerProperty {
  /* display: flex;
    justify-content: center;
    width: 100%;
    height: 55px; */
  /* display: flex;  */
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  display: inline-grid !important;
}

.otpInputField {
  width: 55px !important;
  text-align: center;
  height: 55px;
  border: none;
  font-family: Inter;
  font-size: 21px !important;
  font-weight: 500 !important;
  line-height: 25.41px;
  text-align: left;
  color: #333333;
}
input.otpInputField::placeholder {
  color: #333333;
  font-size: 21px;
  font-weight: 500;
}
input.otpInputField {
  color: #333333;
  font-size: 21px;
  font-weight: 500;
}
.errorMessage {
  margin-top: 5px;
  color: red;
  /* font-size: .875em; */
  font-size: 12px !important;
}
.arrowLeftIcon {
  margin-top: -2px;
}
