body {
  height: 100%;
  margin: 0px;
  overscroll-behavior: none;
  padding: 0px;
  font-family: Inter !important;
}

html {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 0px;
}

a {
  list-style-type: none;
  text-decoration: none;
}

a.carousel-control-prev {
  display: none;
}

a.carousel-control-next {
  display: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 12px !important;
  height: 12px !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 1;
  transition: opacity 0.6s ease;
  border-radius: 50%;
  background: transparent !important;
  border: 1px solid #fff !important;
}

.carousel-indicators .active {
  background-color: #fff !important;
}

.carousel .carousel-indicators {
  bottom: 0;
  left: 0;
  right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

div.radio-with-Icon {
  display: flex;
  width: 100%;
  /* min-height: 130px; */
  max-height: auto;
}

div.radio-with-Icon p.radioOption-Item {
  display: inline-block;
  box-sizing: border-box;
  /* margin: 25px 45px; */
  margin: 12px 10px;
  border: none;
}

div.radio-with-Icon p.radioOption-Item label {
  display: block;
  height: 55px;
  width: 180px;
  border-radius: 7px;
  border: 2px solid #d4d4d4;
  color: #828282;
  cursor: pointer;
  transition: none;
  font-size: 14px;
  padding-top: 16px;
  text-align: center;
  margin: 0 !important;
  padding: 10px 20px;
  font-weight: 500;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.radio-with-Icon p.radioOption-Item input[type="radio"] {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

div.radio-with-Icon p.radioOption-Item input[type="radio"]:checked ~ label {
  opacity: 1;
  border: none;
  color: #5da128;
  border: 2px solid #5da128;
  font-weight: 600;
  display: flex;
  align-items: center;
}

p.radioOption-Item h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  padding-left: 10px;
}

input.form-control.form-control-sm {
  padding-left: 43px;
}

button.btn.btn-primary {
  background: #1d4488 !important;
  border: none !important;
  font-size: 18px;
  /* font-weight: 500; */
}

button#dropdown {
  background: none !important;
  padding: 0px !important;
}

a {
  text-decoration: none !important;
}

ul#justify-tab-example {
  background: #f1f1f1;
  padding: 4px;
  border-radius: 4px;
}

button#justify-tab-example-tab-profile,
button#justify-tab-example-tab-longer-tab,
button#justify-tab-example-tab-permissinon,
button#justify-tab-example-tab-messagelevel,
button#justify-tab-example-tab-home {
  color: #828282;
  font-weight: 500;
  border: none;
}

.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #1d4488 !important;
  border-radius: 4px !important;
}

ul#justify-tab-example1 {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border: none;
  width: 100%;
  max-width: 332px;
}

button#justify-tab-example1-tab-dailyevent,
button#justify-tab-example1-tab-event {
  color: #828282;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
}

button#justify-tab-example-tab-profile:hover,
button#justify-tab-example-tab-longer-tab:hover,
button#justify-tab-example-tab-permissinon:hover,
button#justify-tab-example-tab-messagelevel:hover,
button#justify-tab-example-tab-home:hover {
  border: none;
  outline: none;
  background-color: none;
}

button.btn.btn-primary.changeLevel {
  padding: 9px 11px;
  font-size: 15px;
}

th,
td {
  white-space: nowrap;
}

select,
textarea:focus,
input:focus {
  outline: none;
  border: none;
}

button.btn.btn-primary.msgLevelbtn {
  width: 100%;
  height: 50px;
}

.modal-footer {
  border: none !important;
  margin-top: -20px;
}

.passwordIcon {
  /* position: relative;
    top: -35px;
    left: 479px; */
  color: #828282;
}

select {
  appearance: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown {
  text-align: center;
}

/* Edited */
.form-control {
  height: 50px;
  font-size: 12px;
  color: #000;
  /* background-color: red;  */
}

.tdtextred {
  color: #ff0000 !important;
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  line-height: 18.15px;
}

.tdtext {
  color: #202020 !important;
  font-family: "Inter";
  font-weight: 500;
  font-size: 15px;
  line-height: 18.15px;
}

.container-fluid {
  width: 100%;
  padding: 0;
  margin: 0;
}

.duty-hours-header {
  font-weight: 500;
  font-size: 18px;
  line-height: 21.78px;
  color: #333333;
  margin-bottom: 12px;
  /* Add margin below Duty Hours text */
}

.day-block {
  border: 1px solid #e3e3e3;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  /* Add margin between day boxes */
}

.day-block.active {
  background-color: #f9f9f9;
  border-color: #e3e3e3;
}

.day-block.inactive {
  background-color: #f9f9f9;
  border-color: #e3e3e3;
}

.day-name {
  font-weight: 600;
  font-size: 15px;
  line-height: 18.15px;
  color: #202020;
}

.time-selector {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-picker {
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  padding-right: 30px;
}

.time-icon {
  position: absolute;
  right: 10px;
  width: 15px;
  height: 15px;
  pointer-events: none;
}

.time-selector + .time-selector {
  margin-left: 10px;
}

.toggle-switch .form-check-input:checked {
  background-color: #1d4488;
  border-color: #1d4488;
}

.toggle-switch .form-check-input {
  background-color: #b9b9b9;
  border-color: #b9b9b9;
  box-shadow: none;
  width: 30px;
  height: 18.75px;
}

.totext {
  font-weight: 600;
  font-size: 12px;
  line-height: 14.52px;
  color: #000000;
}

/*===================Mohit work==================================*/
.container {
  max-width: unset !important;
}

button#justify-tab-example-tab-event {
  color: gray !important;
}
button#justify-tab-example-tab-event.active {
  color: white !important;
}

.react-datepicker__input-container input {
  font-size: 15px;
  font-family: "Inter";
  font-weight: 400;
  color: #555555;
}

.clockIcom .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 500;
  font-size: 15px !important;
}

.clockIcom
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  width: 35px;
  height: 35px;
  color: #acacac;
  position: relative;
  right: 5px;
}

.form-check-input {
  border-color: #acacac;
}

.form-check-input:checked {
  background-color: #5da128;
  border-color: #5da128;
}

input.AddEvent_addDeviceProperty__eADw2.AddEvent_inputStyle__oGt71.form-control {
  font-weight: 400;
  font-size: 15px;
  font-family: "Inter";
}

.MuiTextField-root {
  width: 100%;
}

.invalid-feedback {
  font-size: 15px !important;
}

.modal-title {
  font-size: 22px;
  font-weight: 700;
  color: #000;
}

.contactInputFieldProperty {
  color: red;
}

.errorMessage {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.text-danger {
  color: red;
}

.text-warning {
  color: orange;
}

.react-tel-input .form-control {
  width: 100% !important;
  outline: none;
  height: 50px !important;
}

.react-select__control.css-13cymwt-control {
  height: 50px !important;
}

.headingContent {
  color: #000000;
  font-family: Inter;
  font-weight: 600;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0px;
  font-size: 31px;
  margin-bottom: 5px;
}

.paragraphText {
  color: #828282;
  font-family: Inter;
  display: flex;
  justify-content: center;
  font-style: italic;
  font-weight: 400;
  margin: 0px;
  font-size: 20px;
}

.registerFormsField {
  padding-top: 30px;
  padding-left: 0px;
  padding-right: 0px;
}

::placeholder {
  color: #c2c2c2;
  font-size: 12px;
  font-weight: 400;
}

.forgetPasswordText {
  display: flex;
  justify-content: flex-end;
  color: #5da128;
  font-weight: 600;
  /* margin-bottom: 35px; */
}

.buttonAndContent {
  font-family: Inter;
  display: flex;
  justify-content: space-between;
}

.customLink {
  text-decoration: none;
  color: #5da128;
  font-weight: 600;
  font-size: 15px;
  margin-top: -5px;
}

.customLinkSignUp {
  color: #1d4488;
  text-decoration: none;
  font-weight: 600;
}

.paragraphLink {
  font-weight: 500;
  margin: 0px;
  padding: 5px 0px;
}

.buttonProperty {
  background: #1d4488 !important;
  border: none !important;
  font-weight: 600 !important;
  min-width: 90px;
  max-width: auto;
}
/* .svg.css-tj5bde-Svg {
  color:#0c010196;
} */
.react-select__indicator.react-select__dropdown-indicator.css-1xc3v61-indicatorContainer {
  color: rgba(0, 0, 0, 0.582);
}

.formPadding {
  padding-bottom: 20px;
}

.formPadding1 {
  padding-bottom: 15px;
}

.PreviousLink {
  font-weight: 500;
  padding: 5px 0px 0px 0px;
}

.checkboxSelect {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.buttonFontWidth {
  font-weight: 600;
}

.selectIconProperty {
  position: relative;
  top: 28px;
  left: -6px;
  z-index: 999;
}

.passwordFieldProperty {
  padding-bottom: 0px;
  position: relative;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-invalid:not([multiple]):not([size]) {
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* css select state validation starts here */
.react-select__control {
  border-color: #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;
}
/* tr:hover{
  background-color: rgba(201, 202, 204, 0.384);
} */

.react-select__control:hover {
  border-color: #80bdff;
}

.react-select-error .react-select__control {
  border-color: red !important;
  box-shadow: none !important;
}

.react-select__control--is-focused {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-select__control--is-focused.react-select-error {
  border-color: red !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;
}

.react-select__menu {
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

/* css select state validation end here */

/* .selectIconPropertyDate {
  position: absolute;
  width: 25px;
  top: 508px;
  right: 23px;
  height: 40px;
} */
.selectIconPropertyDate {
  width: 25px;
  height: 40px;
  position: relative;
  top: -45px;
  left: 431px;
}

.selectIconPropertyModel {
  position: relative;
  top: 32px;
  right: 6px;
}

.selectIconLocaton {
  position: relative;
  top: 26px;
  right: 6px;
}

.selectIconPropertyPassword {
  position: absolute;
  /* border: 1px solid; */
  width: 25px;
  top: 46px;
  right: 10px;
}

.selectFieldMargin {
  margin-top: -15px;
}

.setLogoOnDashboard {
  padding: 8px 6px 8px 6px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #dfdfdf;
}

.sidebarParent {
  width: 100%;
}

.listStyleProperty {
  font-family: Inter;
  line-height: 1.6;
}

.setSearchIcon {
  position: relative;
  top: 55px;
  left: 15px;
  display: flex;
  width: 20px;
  height: 20px;
  padding-left: 20px;
}

.searchText {
  margin: 0px;
  padding-left: 20px;
  font-family: Inter;
}

.setSearchButton {
  display: flex;
  justify-content: space-around;
}

.tableHeadProperty {
  background-color: black !important;
  color: white !important;
}

.tableBodyProperty tr:nth-child(even) {
  background-color: gray !important;
}

/*  */
.offSetScreen {
  width: 20%;
  height: 701px;
}

.mainSidebarParent {
  display: flex;
}

.mainSectionContent {
  width: 80% !important;
}

.headSectionIcon {
  width: 100% !important;
  height: 80px;
  background-color: #1d4488;
  display: flex;
  justify-content: end;
  align-items: center;
}

.modelButtonAlign {
  display: flex !important;
  justify-content: end !important;
  padding: 22px !important;
}

.setSecarchFields {
  margin-top: -20px;
  width: 370px;
}

.listItems {
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 3;
  text-align: left;
}

@media screen and (max-width: 575px) and (min-width: 320px) {
  .buttonProperty {
    width: 100% !important;
    height: 45px;
    order: 1 !important;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse !important;
  }

  .textCol {
    order: 2 !important;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .paragraphText {
    color: #828282;
    font-family: Inter;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-size: small;
  }
}

@media (max-width: 510px) {
  div.radio-with-Icon {
    display: inline-grid !important;
    width: 100%;
    /* min-height: 130px; */
    /* max-height: auto; */
    justify-content: center;
  }

  div.radio-with-Icon p.radioOption-Item {
    display: inline-block;
    box-sizing: border-box;
    /* margin: 25px 45px; */
    margin: 0px 0px;
    border: none;
  }
}

.mainSidebarParent {
  display: flex;
}

.offSetScreen {
  display: block;
  background: #f8f9fa;
  height: 100vh;
  padding: 0px;
  width: 20%;
}

.setLogoOnDashboard {
  text-align: center;
  margin-bottom: 20px;
}

.listItems {
  padding: 10px;
  cursor: pointer;
}

.listItems.active {
  color: green;
}

.setImageProperty {
  width: 50px;
  height: 50px;
}

.searchAndButton {
  margin-top: 20px;
}

.modelButtonAlign {
  text-align: right;
}

.tableHeadProperty {
  background-color: #343a40;
  color: white;
}

/*==============Offcanvas====================*/
button.d-md-none.btn.btn-primary.toggleSidebar {
  background: none;
  border: none;
  position: absolute;
  top: 20px;
  color: #fff;
}

button.d-md-none.btn.btn-primary.toggleSidebar svg {
  font-size: 28px;
}

/*==============Phone-input====================*/
.phone-input-wrapper .PhoneInputInput {
  border: none;
  outline: none;
  border: 1px solid #dee2e6;
  border-left: none;
}

.phone-input-wrapper .PhoneInput {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 0px;
}

.PhoneInputCountrySelectArrow {
  display: none !important;
}

.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.PhoneInputCountryIcon--border {
  background-color: #fff !important;
  box-shadow: none !important;
}

.phone-input-wrapper .PhoneInputCountryIcon {
  margin-top: -7px !important;
}

.phone-input-wrapper .react-phone-input__flag {
  border-radius: 50%;
  overflow: hidden;
}

.phone-input-wrapper .flag-image {
  border-radius: 50%;
  overflow: hidden;
}

.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
  height: 25px;
  width: 25px;
  object-fit: cover;
}

.phone-input-wrapper .PhoneInputCountry {
  display: flex;
  align-items: center;
}

.phone-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.phone-input-wrapper .countryImg {
  position: absolute;
  left: 50px;
  top: 18px;
  width: 18px;
}

.phone-input-wrapper .PhoneInputCountryIcon {
  margin-top: 0px !important;
  height: 25px !important;
  width: 25px !important;
  object-fit: cover;
}

/*================New css file =================*/
.phone-input-wrapper {
  display: flex;
  align-items: center;
}

.phone-input-wrapper .PhoneInput {
  display: flex;
  align-items: center;
  width: 100%;
}

.phone-input-wrapper .PhoneInputCountry {
  display: flex;
  align-items: center;
  margin-right: -10px !important;
}

.phone-input-wrapper .PhoneInputCountrySelect {
  height: calc(1.5em + 0.75rem + 2px);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem;
}

.phone-input-wrapper .PhoneInputCountryIcon {
  margin-right: 8px;
}

.phone-input-wrapper .PhoneInputInput {
  width: 100%;
  height: 38px;
  border: none;
  outline: none;
}

/* Edited */
.form-select {
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  /* background-color: blue;
    color: pink; */
}

.form-control .phone-input .PhoneInput {
  height: 50px;
  font-size: 12px;
  color: #989898;
}

.contatInptFieldProperty {
  font-size: 14px;
}

/* homepage end... */
.registerFormsField {
  padding-top: 12px;
  padding-left: 0px;
  padding-right: 0px;
}

::placeholder {
  color: #555555;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

Label {
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  color: #333333;
}

.forgetPasswordText {
  display: flex;
  justify-content: flex-end;
  color: #5da128;
  font-weight: 600;
  /* margin-top: 10px; */
  margin-top: 15px;
  margin-bottom: 35px;
}

.buttonAndContent {
  font-family: Inter;
  display: flex;
  justify-content: space-between;
}

.customLink {
  text-decoration: none;
  color: #5da128;
}

.customLinkSignUp {
  color: #1d4488;
  text-decoration: none;
  font-weight: 600;
}

.paragraphLink {
  font-weight: 500;
  margin: 0px;
  padding: 5px 0px;
}

.buttonProperty {
  background: #1d4488 !important;
  border: none !important;
  font-weight: 600 !important;
  max-width: auto;
  font-size: 18px;
  padding: 8px 36px;
}

.formPadding {
  padding-bottom: 20px;
}

.buttonFontWidth {
  font-weight: 600;
}

.selectIconProperty {
  position: relative;
  top: 29px;
  left: -6px;
  pointer-events: none;
}

.selectFieldMargin {
  margin-top: -15px;
  font-size: 12px;
}

span.react-select__indicator-separator.css-1u9des2-indicatorSeparator {
  display: none;
}

/* .setLogoOnDashboard {
 
  display: flex;
  justify-content: center;
  } */

.sidebarParent {
  width: 100%;
}

.listStyleProperty {
  font-family: Inter;
  line-height: 1.6;
}

.setSearchIcon {
  position: relative;
  top: 55px;
  left: 15px;
  display: flex;
  width: 20px;
  height: 20px;
  padding-left: 20px;
}

.searchText {
  margin: 0px;
  padding-left: 20px;
  font-family: Inter;
}

.setSearchButton {
  display: flex;
  justify-content: space-around;
}

.dateCalenderImage {
  position: relative;
  top: -50px;
  right: 0px;
  pointer-events: none;
  /* display: flex;
    justify-content: end; */
}

/* styles.css or your specific stylesheet */
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
}

.form-control.is-invalid ~ .form-control-feedback {
  display: block;
  color: #dc3545;
}

.tableBodyProperty tr:nth-child(even) {
  background-color: gray !important;
}

.offSetScreen {
  width: 20%;

  height: auto;
}

.mainSidebarParent {
  display: flex;
}

.mainSectionContent {
  width: 80% !important;
}

.headSectionIcon {
  width: 100% !important;
  height: 80px;
  background-color: #1d4488;
  display: flex;
  justify-content: end;
  align-items: center;
}

.modelButtonAlign {
  display: flex !important;
  justify-content: end !important;
  padding: 22px !important;
}

.setSecarchFields {
  margin-top: -20px;
  width: 370px;
}

.listItems {
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 3;
  text-align: left;
}

@media screen and (max-width: 575px) and (min-width: 320px) {
  .buttonProperty {
    width: 100% !important;
    height: 45px;
    order: 1 !important;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse !important;
  }

  .textCol {
    order: 2 !important;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .paragraphText {
    color: #828282;
    font-family: Inter;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-size: small;
  }

  .mainSectionContent {
    width: 80% !important;
    margin-bottom: 140px;
  }

  /* div.radio-with-Icon {
    display: inline-grid;
    justify-content: center;
    padding: 0px;
  } */
  /* div.radio-with-Icon p.radioOption-Item {
    display: inline-block;
    box-sizing: border-box;

    border: none;
} */
  /* div.radio-with-Icon {
  display: inline;
  width: 100%;
  max-height: auto;
  justify-content: center;
} */
}

.mainSidebarParent {
  display: flex;
  height: 100%;
}

.offSetScreen {
  width: 20%;
  display: block;
  background: #f8f9fa;
  border-right: 1px solid #dfdfdf;
}

.setLogoOnDashboard {
  text-align: center;
  margin-bottom: 20px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listItems {
  padding: 10px;
  cursor: pointer;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
}

.arrowIcon {
  width: 20px;
  height: 20px;
}

.activeIcon {
  color: #5da128;
}

.unactiveIcon {
  color: #202020;
}

.link {
  color: #202020;
  font-weight: 700;
  text-decoration: none;
}

.link.active {
  color: #5da128;
  font-weight: 700;
  text-decoration: none;
}

.listItems.active {
  position: relative;
  color: #5da128;
  padding-left: 15px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}

.listItems.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-left: 2px solid #5da128;
  height: 10px;
}

.setTableFromBottom {
  /* overflow-x: scroll; */
  overflow-x: auto;
  width: 100%;
  /* height: 300px; */
  overflow: auto;
  height: 55vh;
}

.setImageProperty {
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  object-fit: cover;
}

.searchAndButton {
  margin-top: 20px;
}

.modelButtonAlign {
  text-align: right;
}

.tableHeadProperty tr th {
  background: #202020;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  padding-bottom: 12px;
  /* width: 200px; */
}

.tableHeadProperty {
  height: 50px;
}

Table thead tr {
  border-radius: 6px 6px 0px 0px;
}

th,
td {
  white-space: nowrap;
}

.customTable thead th:first-child {
  border-top-left-radius: 6px;
}

.customTable thead th:last-child {
  border-top-right-radius: 6px;
}

Table tbody tr td {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.15px;
  text-align: left;
  color: #000000;
  border-bottom: none;
}

Table tbody tr td::first-letter {
  text-transform: capitalize !important;
}

.dropdownIcon {
  display: flex;
  justify-content: space-between;
}

.iconColor {
  color: #eb3349 !important;
}

.custom-dropdown-toggle::after {
  display: none !important;
}

.setParentDropdown {
  padding: 0px 10px !important;
  color: #000000;
  background-color: transparent;
}

.setTextPlaceholder {
  color: #555555 !important;
  font-size: 12px;
  font-weight: 400;
}

.modelButtonPropwerty {
  text-align: right;
  padding: 0px 0px;
}

.dropdownIcons {
  width: 20px;
  height: 20px;
}

.dropdownTextProperty {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.15px;
  text-align: left;
  padding: 4px;
}

.AddSiteSubmitButton {
  background-color: #1d4488 !important;
  color: #ffffff;
  border: 1px solid #1d4488 !important;
  font-family: Inter;
  width: 100%;
  height: 50px;
  border-radius: 6px 6px 6px 6px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
}

.closeIconProperty {
  color: #ff0000;
  width: 28px !important;
  height: 28px !important;
  cursor: pointer;
}

@media (max-width: 575px) {
  .modelButtonPropwerty {
    text-align: center;
    padding: 0px 0px;
  }

  /* div.radio-with-Icon p.radioOption-Item {
    display: inline-flex;
    justify-content: center;
    box-sizing: border-box;
    margin: 0px 10px;
    border: none;
    } */
  /* div.radio-with-Icon p.radioOption-Item {
      display: inline-block;
      box-sizing: border-box;
      margin: 0px 4px;
      border: none;
  } */
}

@media (max-width: 768px) {
  .offSetScreen {
    display: none;
    width: 20%;
  }

  .toggleButton {
    display: block;
    top: 20px;
    width: 70px;
    position: absolute;
    padding-left: 20px;
  }

  .setSecarchFields {
    margin-top: -20px;
    width: 300px;
  }

  .modelButtonAlign {
    display: flex;
    justify-content: center;
    padding: 15px;
  }

  .mainSectionContent {
    width: 80% !important;
    margin-bottom: 100px;
  }

  .headerContent {
    display: flex;
    justify-content: center;
    font-size: 22px;
    text-align: center;
  }

  .paraGraphContent {
    display: flex;
    justify-content: center;
    text-align: justify;
  }

  .bottomContent {
    width: 100%;
    font-family: Inter;
    color: #ffffff;
    padding-top: 20px;
  }

  .carouselContent {
    position: relative;
    top: -30px;
    display: flex;
    justify-content: end;
  }

  div.radio-with-Icon {
    display: flex;
    width: 100%;
    /* min-height: 130px; */
    max-height: auto;
    justify-content: center;
  }
}

@media (min-width: 769px) {
  .toggleButton {
    display: none;
  }

  /* div.radio-with-Icon p.radioOption-Item {
    display: inline-block;
    box-sizing: border-box;
    margin: 10px 40px;
    border: none;
} */

  div.radio-with-Icon {
    display: flex;
    width: 100%;
    height: 130px;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .mainSectionContent {
    width: 80% !important;
    /* margin-bottom: 100px; */
  }

  .headerContent {
    display: flex;
    justify-content: center;
    font-size: 22px;
    text-align: center;
  }

  .paraGraphContent {
    display: flex;
    justify-content: center;
    text-align: justify;
  }

  .bottomContent {
    width: 100%;
    font-family: Inter;
    color: #ffffff;
    padding-top: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 768px) and (min-width: 577px) {
  .carouselHeading {
    font-family: Inter;
    font-size: 25px;
    font-weight: bold;
    line-height: 49.7px;
    text-align: right;
  }
}

@media (max-width: 1024px) {
  .mainSectionContent {
    width: 80% !important;
    /* margin-bottom: 100px; */
  }
}

a.d-flex.justify-content-between.align-items-center.bg-none.dropdown-item {
  background: none !important;
}

.tableResizer {
  width: 100%;
  max-width: 1059px;
}

button.modalTextButton.btn.btn-primary {
  background-color: #5da128 !important;
}

/*============Sushil work================*/
@media only screen and (max-width: 380px) {
  .carousel .carousel-indicators {
    bottom: -13px;
    right: -33px;
  }
}

@media only screen and (max-width: 429px) {
  /* div.radio-with-Icon p.radioOption-Item {
width: 130px;
height: 130px;
} */

  /* div.radio-with-Icon p.radioOption-Item label {
padding: 22px 0px;
} */
  .txtSlect p {
    color: #828282;
    font-size: 16px !important;
    font-weight: 400;
    max-width: 507px;
  }
}

@media only screen and (max-width: 768px) {
  .mainSectionContent {
    width: 100% !important;
    margin-bottom: 0px;
  }
}

.modelButtonPropwerty {
  text-align: right;
  padding: 0px 0px;
}

/* div.radio-with-Icon p.radioOption-Item {
      display: inline-block;
      width: 100px;
      height: 70px;
      box-sizing: border-box;
      margin: 25px 45px;
      border: none;
  } */

.mauto {
  margin: auto;
}

span.logoutIcon {
  color: #ff0000;
}

/*===============16/7/2024===================*/
p.text-danger {
  font-size: 0.875em;
}

ul.dropdown.settingDropdoen li {
  border: 1px solid #f1f1f1;
  /* height: 35px; */
  text-align: left;
  padding: 10px;
  align-items: center;
  display: flex;
  font-size: 12px;
  color: #717171 !important;
  background-color: #f8f8f8;
}

ul.dropdown.settingDropdoen li a {
  color: #717171;
  font-weight: 500;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  /* max-width: 466px; */
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

/* .AddDevice_addDeviceProperty__8F672 input {
  height: 50px;
  margin-top: 10px;
} */

.settingActive {
  background-color: #e1ebd9;
  color: #5da128 !important;
  font-size: 12px;
  font-weight: 500;
}

.settingActive a {
  color: #5da128 !important;
  font-size: 12px;
  font-weight: 500;
}

.settingInactive {
  background-color: #f4f4f4;
  color: #000 !important;
  font-size: 12px;
}

.settingInactive a {
  color: #717171 !important;
  font-size: 12px;
  font-weight: 500;
}

ul.dropdown.settingDropdown li {
  padding: 10px;
  text-align: left;
}

.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: none !important;
}

button#dropdown-basic {
  border: none;
}

.dropdownTextProperty a {
  display: flex;
  justify-content: space-between;
  background-color: none;
  align-items: center;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000 !important;
  text-decoration: none !important;
  background-color: #fff !important;
}

.form-check-input:checked::before {
  left: 27px;
  /* Move the white ball to the right when active */
  background-color: #f0f0f0;
  /* Change ball color when active */
}

/* Change ball color when inactive */
.form-check-input:not(:checked)::before {
  background-color: #ff9800;
  /* Inactive ball color */
}

textarea#textarea-description {
  height: 80px;
}

.personTableData {
  width: 100%;
  height: 50vh;
  overflow: auto;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);
  border-radius: 6px;
}

input#input-tts_prompt {
  margin-top: -20px;
}

input#input-audio_prompt {
  margin-top: -20px;
}

audio.react-audio-player {
  margin-top: 10px;
}

.active > .page-link {
  background-color: #1d4488 !important;
  border-color: #1d4488 !important;
}

/* input#input-promptIntroAudio{
  padding-left: 25px;
} 

 input#input-audio_prompt {
  padding-left: 25px;
} */

input#input-promptIntroAudio.form-control {
  padding: 0.375rem 1.75rem !important;
}

input#input-promptIntroAudio.form-control::placeholder {
  padding: 0.375rem 0.75rem !important;
}

input#input-audio_prompt.form-control {
  padding: 0.375rem 1.75rem !important;
}

input#input-audio_prompt.form-control::placeholder {
  padding: 0.375rem 0.75rem !important;
}

/*========21-08-24=========*/
.formPadding.textArealabel label {
  font-size: 18px;
  margin-top: 10px;
}

/* .tdtextNotes {
  width: 200px;
  height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  line-height: 20px; 
  word-break: break-word; 
  white-space: normal; 
  margin: 0; 
  padding: 0; 
}
.tdContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center; 
  height: 100%;
} */
.tdtextNotes {
  /* width: 200px; */
  min-width: auto;
  max-width: 220px;
  height: 50px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  /* display: flex;
  align-items: center; 
  justify-content: center; */
  vertical-align: middle;
}

.tdContentWrapper {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  word-break: break-word;
  white-space: normal;
  width: 100%;
  /* background-color: red; */
}

.custom-textarea::placeholder {
  font-size: 15px; /* Set font size */
  font-weight: 400; /* Set font weight */
  color: #555555; /* Optional: Set a color for the placeholder text */
}
/* .Event_customTable__11UTG th:last-child{
  border-top-right-radius: 6px;
  display: flex;
  justify-content: center;
  border: none;
  height: 50px;
  align-items: center;

} */

.person-list-container {
  height: 250px; /* Set a height that makes sense for your modal */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 10px; /* Optional: Add some spacing */
  scrollbar-width: thin;
}

.form-select {
  background: none;
}
input#input-AlertToneAudio {
  padding: 0.375rem 1.75rem !important;
}

input#input-AlertToneAudio.form-control::placeholder {
  padding: 0.375rem 0.75rem !important;
}

td.tdtextNotes.tdtext {
  /* width: 200px; */
  min-width: 200px !important;
}

/* .popover-body {
  position: absolute;
  background-color: white;
  left: -293px;
  top: 0;
  width: 330px;
  height: 270px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
} */

.notify_toast_firebase {
  z-index: 99999 !important;
  position: fixed;
  background-color: whitesmoke;
  top: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.notify_toast_firebase .toast-header strong {
  color: black;
}

.notify_toast_firebase .toast-body {
  cursor: pointer;
}
td.tdtext.caregiverPermission {
  white-space: break-spaces;
  width: 360px;
}

button.Notification_responseBtn__q8pAW.undefined.btn.btn-primary.btn-xsm {
  background-color: #5da128 !important;
  width: 70px;
  height: 30px;
}
.popover-body {
  position: absolute;
  width: 100%;
  padding-top: 41px;
}

.checkBoxReminder {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: 400;
}

.MuiMultiSectionDigitalClock-root ul {
  width: unset;
}
