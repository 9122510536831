.profileProperty {
  padding: 20px 20px;
}

.userProfiile {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  color: #000000;
}
.paragraphInformation {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #000000;
}
.getUserProfile {
  padding-top: 40px;
}
.sectionContentPropertyBasic {
  border: 1px solid #dddddd;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.sectionContentPropertyOther {
  border: 1px solid #dddddd;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  justify-content: flex-start;
}
.imageStyleProperty {
  width: 120px;
  height: 120px;
  border: 2px solid #adadad;
  border-radius: 50%;
  object-fit: cover;
}
.sectionContentPropertyBasic div li {
  text-align: center;
}
.userNameProperty {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 5px;
  color: #202020;
}
.userEmailProperty {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  color: #626262;
}
.userContactProperty {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  color: #1d4488;
  padding: 10px;
}
.OtherInformationProperty {
  padding: 20px 20px;
}
.OtherInformationProperty li {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  color: #828282;
  padding-bottom: 21px;
  /* padding-bottom: 14px; */
}
.OtherInformationProperty li span {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  color: #202020;
}
.buttonContainer {
  padding-top: 20px;
  display: flex;
  justify-content: end;
  color: #1d4488;
}
/* .buttonProperty{
    color:#1D4488 !important;
} */
/* .buttonProperty input{
    color: white !important;
    background-color: #1D4488 !important;
    border: none;
    font-family: Inter;
    font-size: 17px;
    font-weight: 600;

} */
/* .buttonProperty.StakeholderProfile_buttonProperty__-XbkB {
    background-color: #1D4488 !important;
    border: none;
} */
.buttonContainer .buttonProperty {
  background-color: #1d4488 !important;
  border: none;
  font-family: Inter;
  /* font-size: 17px;
    font-weight: 600; */
  padding: 8px 22px;
}
.buttonProperty {
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
}
@media screen and (max-width: 575px) {
  .otherDetailProperty {
    margin-top: 20px;
  }
}
