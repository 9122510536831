.eventTable {
  width: 100%;
  height: 55vh;
  overflow: auto;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);
  border-radius: 6px;
}
.customTable th:last-child {
  border-top-right-radius: 6px;
  display: flex;
  justify-content: flex-end;
  border: none;
  height: 50px;
  align-items: center;
}

.setEventDropdown {
  padding: 0px 10px !important;
  color: #000000;
  background-color: transparent;
  justify-content: end;
  display: flex;
}
p.Eventproperty {
  font-size: 22px;
  font-weight: 700;
  color: #000;
}
.eventAction1 th {
  border-top-right-radius: 6px;
  display: flex;
  justify-content: flex-end;
  border: none;
  height: 50px;
  align-items: center;
}
.eventAlignleft {
  text-align: left;
}
.alignRight {
  text-align: right;
  /* width:100px; */
}
.tableHeadProperty tr th {
  background: #202020;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 12px;
}
.EventalignLeft {
  text-align: left;
}

.customTable thead {
  vertical-align: center;
}

.deleteClass {
  text-align: center;
}

.deleteClass p {
  font-size: 18px;
  width: 100%;

  font-weight: 700;
  padding: 0px 49px;
  margin: 20px 0px;
}

.heightSection {
  height: 50px !important;
}
.noRecord p {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.noRecord {
  width: 100%;
  text-align: center;
  margin-top: -270px;
  max-width: 100%;
}

.setEventDropdown.dropdown-menu.show {
  height: 20px !important;
}
.audioIntro h4 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.paginationContainer {
  margin: 13px;
  justify-content: end;
  display: flex;
}

/* .tableHeadProperty tr td.notesText {
  white-space: break-spaces;
} */

td.notesText {
  white-space: break-spaces;
}
