.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* / background: #f5f5f542; / */
    z-index: 999;
  }
  
  .loader img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 150px;
    margin: auto;
    width: 100px;
  }
  